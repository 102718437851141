.login-page {
     background: url(..//..//images/img2.jpg);
     background-repeat: no-repeat;
     background-size: cover;
     height: 100%;
     width: 100%;
     position: absolute;

     .login-left {
          img {
               left: 50%;
               top: 50%;
               margin-left: -45%;
               position: absolute;
               margin-top: -15%;
               top: 50%;
          }

          .login-btn {
               display: grid;
               width: 80px;
               position: absolute;
               right: 33%;
               top: 100px;

               button {
                    margin: 5px 0px;
                    padding: 2px;
                    font-size: 12px;
                    border-radius: 0px;
                    background-color: #ffffff;
                    color: #000000;
               }
          }
     }

     .login-right {
          background: #ffffff;
          position: absolute;
          height: 100%;
          right: 0px;
          padding: 0px;

          .login-form {
               top: 50%;
               position: absolute;
               margin-top: -25%;
               margin-left: 15%;
               margin-right: 15%;
               width: 70%;
               padding: 0px;
          }

          .registor-form {
               top: 35%;
               position: absolute;
               margin-top: -25%;
               margin-left: 15%;
               margin-right: 15%;
               width: 70%;
               padding: 0px;
          }
     }
}

@media only screen and (max-width: 768px) {
     .login-page {
          .login-left {
               text-align: center;

               img {
                    width: auto;
                    height: 100px;
                    position: static;
                    margin-top: 0px;
                    margin-left: 0px;
               }

               .login-btn {
                    right: 0;
                    z-index: 1;
               }
          }

          .login-right {
               background: #fff;
               position: absolute;
               height: 85%;
               right: 0px;
               padding: 0px;
               top: 15%;
          }
     }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
     .login-page {
          .login-left {
               width: 50%;

               img {
                    width: auto;
                    height: 100px;
                    position: static;
                    margin-top: 0px;
                    margin-left: 0px;
               }
          }

          .login-right {
               top: 0px;
               width: 50%;
               height: 100%;
          }
     }
}

// .login {
//      content: url("../../../public/login.jpg");
//      height: 800px;
//      width: 200px;
//      object-fit: fill;
// }