@import "./Variable.scss";

.main-page.expand {
    width: calc(100% - 48px);
    margin-left: 48px;
}

.main-page {
    width: calc(100% - 200px);
    margin-left: 200px;
    margin-top: 70px;

    .main-content {
        padding: 0px;
    }
}

.panel {
    position: fixed;
    height: calc(100% - 48px);
    width: 600px;
    right: 0px;
    top: 50px;
    // padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 5px #333;
    z-index: 1000;

    .panel-close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .panel-header {
        padding: 20px 0px 0px 20px
    }

    .panel-content {
        padding: 20px;
        height: calc(100% - 70px);
        overflow: auto;
        padding-bottom: 80px;
        // .dync-card{
        //     margin: 0px -12px;
        // }
    }

    .add-address-btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* margin-left: auto; */
        /* float: right; */
        position: absolute;
        bottom: 70px;
        right: 10px;
    }

    .add-partsave-btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* margin-left: auto; */
        /* float: right; */
        position: absolute;
        bottom: 70px;
        right: 40px;
        // visibility: hidden;
    }

    .close-address-btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* margin-left: auto; */
        /* float: right; */
        position: absolute;
        border-top: 100px;
        right: 10px;
    }

    .panel-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        box-shadow: 0 -5px 5px -5px #333;
        background-color: $White-color;

        .btn-grp {
            padding: 10px 15px;

            .btn {
                margin: 0px 5px;
                width: 100px;
            }
        }
    }
}

.view {
    position: fixed;
    height: calc(100% - 48px);
    width: 600px;
    right: 0px;
    top: 50px;
    // padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 5px #333;
    z-index: 1000;

    .view-close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .view-header {
        padding: 20px 0px 0px 20px
    }

    .view-content {
        padding: 20px;
        height: calc(100% - 70px);
        overflow: auto;
        padding-bottom: 80px;
        // .dync-card{
        //     margin: 0px -12px;
        // }
    }

    .add-address-btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* margin-left: auto; */
        /* float: right; */
        position: absolute;
        bottom: 70px;
        right: 10px;
    }

    .add-partsave-btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* margin-left: auto; */
        /* float: right; */
        position: absolute;
        bottom: 70px;
        right: 40px;
        // visibility: hidden;
    }

    .close-address-btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* margin-left: auto; */
        /* float: right; */
        position: absolute;
        border-top: 100px;
        right: 10px;
    }

    .view-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        box-shadow: 0 -5px 5px -5px #333;
        background-color: $White-color;

        .btn-grp {
            padding: 10px 15px;

            .btn {
                margin: 0px 5px;
                width: 100px;
            }
        }
    }
}

.overlay {
    background-color: rgba(51, 51, 51, 0.486);
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0px;
    right: 0px;
    top: 48px;
    position: fixed;
}

.more-action {
    button::after {
        display: none;
    }
}

.table-dropdown {
    margin-left: 10px;
}

@media only screen and (max-width: 768px) {
    .popover-custom {
        max-width: 100%;
        padding: 10px;
    }

    .main-page {
        .main-content {
            .seach-popover {
                display: block;
                margin: 0px auto;
            }
        }
    }

    .panel {
        width: 100% !important;
    }

    .custom-table {
        .mobile-table-tr {
            .mobile-table-td {
                display: flex;

                .mobile-td-data.w20 {
                    width: 20%;
                }

                .mobile-td-data.w30 {
                    width: 30%;
                }

                .mobile-td-data.w40 {
                    width: 40%;
                }

                .mobile-td-data.w50 {
                    width: 50%;
                }

                .mobile-td-data.w100 {
                    width: 100%;
                }

                .mobile-td-data {
                    text-align: left;
                    position: relative;

                    .mobile-table-title {
                        display: block;
                        font-weight: 600;
                    }

                    .mobile-table-title.amount-div {
                        margin-top: 20px;
                        display: flex;
                        font-size: 18px;

                        .mobile-td-icon {
                            font-size: 18px;
                            margin-top: 7px;
                            margin-right: 5px;
                        }
                    }

                    .trans-type {
                        position: absolute;
                        border-radius: 100%;
                        top: 15px;
                        left: 65%;
                    }

                    .mobile-td-icons {
                        display: flex;
                        position: absolute;
                        right: 0px;

                        .mobile-td-icon {
                            padding: 0px 5px;
                            color: rgb(44, 44, 206);
                        }
                    }
                }

            }
        }
    }
}