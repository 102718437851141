@import "../../../css/scss/Variable.scss";

.main-navbar {
  padding: 10px 0px;
  background-color: darkcyan;
  // background-color: $Bg-color;

  .container-fluid {
    padding: 0px;

    .menu-icon {
      color: #ffffff;
    }

    .user-icon {
      .dropdown {
        .dropdown-toggle {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          overflow: hidden;
          padding: 0px;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.company-logo {
  content: url("../../../../public/company-logo.png");
}
