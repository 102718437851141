.content {
    text-align: center;
}

.main-img {
    background: url(..//..//../public/img2.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    margin: 10%;
    margin-left: 1px;
    height: 65%;
    width: 80%;
   
}