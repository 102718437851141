@import "../../../css/scss/Variable.scss";

.sidemenu {
    width: 200px;
    position: fixed;
    left: 0px;
    top: 54px;
    padding: 0px;
    height: 100%;
    z-index: 1;
    border-right: solid 2px $border-color;

    .list-group-item {
        padding: 0px;

        button {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            color: #000000;
            text-decoration: none;
            margin-left: 2px;
            border-left: solid 3px transparent;
            width: 100%;
            padding: 15px 0px 15px 10px;
            text-align: left;
        }

        .btn-active {
            border-left: solid 3px $Bg-color;
            margin-left: 2px;
            border-radius: inherit;
            background: #f3f3f3;
            ;
        }
    }

    .list-group-item:hover {
        background: $navactive-color;
    }

    .sidenav-icon {
        margin-right: 10px;
    }
}

.sidemenu.collapsed {
    width: 48px;

    .list-group {
        .list-group-item {
            padding: 0px;

            button {

                padding: 15px 0px 15px 10px;
                text-align: left;

                svg {
                    margin-top: 5px;
                }

                .sidenav-text {
                    display: none;
                }
            }
        }

        .list-group-item:hover {
            width: 250px;
            background: $navactive-color;

            button {
                display: flex;
                width: 280px;

                .sidenav-text {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .sidemenu {
        position: fixed;
        left: 0px;
        top: 58px;
        height: 100%;
        background: $White-color;
        z-index: 100;
    }

    .main-page {
        width: calc(100% - 48px);
        margin-left: 48px;
        margin-top: 70px;

        .main-content {
            .navbar {
                .container-fluid {
                    padding-left: 0px;

                    .navbar-nav {
                        flex-direction: row;

                        .nav-link {
                            margin: 0px 10px;
                            // .nav-menutext{
                            //     display: none;
                            // }
                        }
                    }
                }
            }
        }
    }

}